body{
    margin: 0!important;
    text-align:"justify";
}
p{
    margin: 0 5vw;
    font-family: 'vazir';
    font-size: large;
}
h1{
    font-size: 1.3rem;
}
.list{
    margin-left: 0!important;
}
.starter{
    background: #DBE2EF;
    background: linear-gradient(180deg, #fafafa 40%, rgba(232,249,253,1) 100%); 

}
.blue{
    background: #e8f9fd; 
}
.purple{
    background: rgb(232,249,253);
    background: linear-gradient(180deg, rgba(232,249,253,1) 41%, rgba(229,234,245,1) 100%);
}
strong{
    font-size: 1.5rem;
    line-height: 3em;
}
.ending{
    background: #e5eaf5;
    background: linear-gradient(180deg, rgba(229,234,245,1) 41%, rgba(255,255,255,1) 100%);
}
.header{
    content:'';
    min-height: 15vh;
    background-color: #fafafa;
    display: 'flex';
    justify-content: space-between;
}
.header2{
    content:'';
    min-height: 10vh;
    background-color: #fafafa;
    display: 'flex';
    justify-content: space-between;
}
.custom-shape-divider-top-1651047448 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1651047448 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1651047448 .shape-fill {
    fill: #DBE2EF;
}