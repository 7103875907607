body{
    margin: 0!important;
    text-align:"justify";
}
@font-face {
    font-family: 'nazanin';
    src: url('../Assets/Nazanin.ttf') format('truetype');
    font-weight: normal;
}

.nazanin {
    font-family: 'vazir' !important;
}

@font-face {
    font-family: 'nazaninbold';
    src: url('../Assets/BNaznnBd.ttf') format('truetype');
    font-weight: normal;
}

.nazaninbold {
    font-family: 'vazir' !important;
}

@font-face {
    font-family: 'quranic';
    src: url('../Assets/arabic.ttf') format('truetype');
    font-weight: normal;
}

.quranic {
    font-family: 'quranic' !important;
}

@font-face {
    font-family: 'titr';
    src: url('../Assets/Titr.ttf') format('truetype');
    font-weight: normal;
}

.Titr {
    font-family: 'titr' !important;
}
p{
    margin: 0 5vw;
    font-family: 'vazir';
    font-size: large;
}
h1{
    font-size: 1.3rem;
}
h2{
    font-family: 'titr';
    text-Align: 'center';
    font-size: '1.2rem';
    margin-top: '1.5em';
}
.list{
    margin-left: 0!important;
}
.starter{
    background: #DBE2EF;
    background: linear-gradient(180deg, #fafafa 40%, rgba(232,249,253,1) 100%); 

}
.blue{
    background: #e8f9fd; 
}
.purple{
    background: rgb(232,249,253);
    background: linear-gradient(180deg, rgba(232,249,253,1) 41%, rgba(229,234,245,1) 100%);
}
strong{
    font-size: 1.5rem;
    line-height: 3em;
}
.ending{
    background: #e5eaf5;
    background: linear-gradient(180deg, rgba(229,234,245,1) 41%, rgba(255,255,255,1) 100%);
}
.header{
    content:'';
    min-height: 15vh;
    background-color: #fafafa;
    display: 'flex';
    justify-content: space-between;
}
.header2{
    content:'';
    min-height: 10vh;
    background-color: #fafafa;
    display: 'flex';
    justify-content: space-between;
}
.custom-shape-divider-top-1651047448 {
    position: relative;
    top: -2;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1651047448 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1651047448 .shape-fill {
    fill:#005691
}
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: '1.2em!important'
  }
  
  .flex-items:nth-child(1) {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    font-family:'Nazanin';
    font-size: '1.2em!important'
  }
  
  .flex-items:nth-child(2) {
    display: block;
    flex-grow: 5;
    flex-shrink: 5;
    flex-basis: auto;
    align-self: auto;
    order: 1;
    font-family:'Nazanin';
    font-size: '1.2em!important'
  }

  td>p{
      text-align: right;
  }
  table tr td > p{
    font-size: 150%;
    font-weight: 1000;
    font-family: "Nazanin";
    align-items: 'flex-start';
}
table tr td {
    text-align: 'right !important'
}
p {
    font-size: 150%;
    line-height: 200%;
    font-weight: 1000;
    font-family: "Nazanin";
    margin-bottom: '2em'
}