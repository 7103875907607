.backgroundaks{
    background-image: url("../Assets/Background.jpg");
    height: 700px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
  .backgroundnawishta{
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
}
.title{
    font-size: clamp(1.8rem, 9vw, 4.5rem);
    font-family: Titr;
    color: #112D4E;
    text-shadow: 2px 2px #ffffff;
    padding-bottom: 0.7rem;
}
.title2{
    font-size: 2.6em;
    font-family: Titr;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
}
.title3{
    font-size: 2.6em;
    font-family: Titr;
    color: #283747;
    padding: 1rem 0;
    text-align: center;
}
.title4{
    font-size: 1.8em;
    font-family: Titr;
    color: #283747;
    padding: 1rem 0;
    text-align: center;
}
.title5{
    font-size: 2.6em;
    font-family: Titr;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
}
.title8{
    font-size: 2.6em;
    font-family: Titr;
    color: #112D4E;
    margin: 1em 0 2em 0;
    text-align: center;
}
.para{
    font-size: clamp(1.1rem, 1.5vw, 3.2rem);
    line-height: clamp(1.5rem, 3.0vw, 3.2rem);
    font-family: vazir;
    text-align: center;
    direction: rtl;
    color: #112D4E;
    padding: 0!important;
    margin: 0!important;
    text-shadow: 2px 2px #ffffff;
}
.para2{
    font-size: 1.6em;
    line-height: 3rem;
    font-family: vazir;
    text-align: justify;
    direction: rtl;
    color: #112D4E;
}
.para3{
    font-size: 1.3em;
    line-height: 2.5rem;
    font-family: vazir;
    text-align: justify;
    direction: rtl;
    color: #112D4E;
}
.ketab{
    margin: 2vh 2vh;
}
.kharid{
font-family: vazir;
}
.center { 
    display: inline-block;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
}
.filler{
    content: '';
    min-height: 20vh;
    background: #283747;
}
.custom-shape-divider-top-1651252755 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1651252755 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 132px;
}

.custom-shape-divider-top-1651252755 .shape-fill {
    fill: #283747;
}

.image{
    border-radius: 50%;
    box-shadow: 3px 3px 10px rgb(160, 156, 156);
    margin-top: 8vh;
}
.image1{
    border-radius: 50%;
    box-shadow: 3px 3px 10px rgb(160, 156, 156);
    margin-top: 7vh;
}
.image2{
    margin-top: 15vh;
}
.filler2{
    content: '';
    min-height: 20vh;
    background: #3F72AF;
}
.filler3{
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 14%, rgba(191,214,240,1) 48%, rgba(63,114,175,1) 100%);
    content: '';
    min-height: 20vh;
}
.custom-shape-divider-top-1651260360 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1651260360 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 154px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1651260360 .shape-fill {
    fill: #fafafa;
}